/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Navigate } from 'react-router-dom';
import moment from 'moment';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import {
  FaCheck,
  FaXmark,
  FaPhone,
  FaCalendar,
  FaClock,
} from 'react-icons/fa6';

import { TestDriveTimeSlot, UserStatus } from '@koeajacom/ka-types/dist/types';

import CustomerAPI from '../../api/customer';

import ActionSpinner from '../../components/common/ActionSpinner';
import StarOverButton from '../../components/customer/StarOverButton';
import TermsAndConditionsModal from '../../components/customer/TermsAndConditionsModal';

interface KoeajaProps {
  userStatus: UserStatus | null;
  api: CustomerAPI;
}

const Koeaja = ({
  userStatus,
  api,
}: KoeajaProps): ReactElement => {
  const [pending, setPending] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(userStatus!.profile!.phoneNumber);
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [time, setTime] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<TestDriveTimeSlot[] | null>(null);
  const [timeSlotsPending, setTimeSlotsPending] = useState<boolean>(true);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const handleCloseModal = () => {
    setShowTermsAndConditionsModal(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      setPending(true);
      const p = userStatus!.profile;
      api.updateProfile({
        emailAddress: p!.emailAddress,
        phoneNumber,
      })
        .then(() => {
          api.applyForPermit({
            startTime: time!,
            date,
          })
            .then(() => {
              setPending(false);
            })
            .catch(() => setPending(false));
        })
        .catch(() => setPending(false));
    }

    setValidated(true);
  };

  const checkDriversLicense = () => {
    setPending(true);
    api.checkDriversLicense()
      .then(() => setPending(false))
      .catch(() => setPending(false));
  };

  const checkCreditInfo = () => {
    setPending(true);
    api.checkCreditInfo()
      .then(() => setPending(false))
      .catch(() => setPending(false));
  };

  const getTimeSlots = async (dateToFetch: string) => {
    if (!userStatus?.vehicle) return;
    setTimeSlotsPending(true);

    setTime('');
    setTimeSlots(null);

    const newTimeSlots = (await api.getTimeSlots(dateToFetch)).timeSlots;
    setTimeSlots(newTimeSlots);
    // setTime(newTimeSlots.length > 0 ? newTimeSlots[0].startTime : '');

    setTimeSlotsPending(false);
  };

  useEffect(() => {
    getTimeSlots(date);
  }, []);

  if (userStatus?.testDrivePermit) return <Navigate to="/koeajo" replace />;
  if (!userStatus?.vehicle) return <Navigate to="/" replace />;

  const v = userStatus.vehicle;
  const dl = userStatus.driversLicense;
  const ci = userStatus.creditInfo;
  const ccr = userStatus.vehicle.dealership.creditCheckRequired;

  return (
    <>
      <TermsAndConditionsModal
        handleClose={handleCloseModal}
        show={showTermsAndConditionsModal}
      />
      <Row className="justify-content-center py-2">
        <Col xs={12} md={10} lg={7} xxl={6}>
          <Card>
            <Card.Header className="fs-4 fs-sm-3 fw-bold py-1">{t('testDrive-title')}</Card.Header>
            <Card.Body>
              <Row>
                <Col className="d-flex fs-2 fw-bold">
                  {v.licensePlate}
                </Col>
              </Row>
              <Row className="fs-5 mt-sm-4 mt-md-5 fs-sm-5">
                <Col>
                  {`${v.make} ${v.model} (${v.color})`}
                </Col>
              </Row>
              <Row className="mt-2 mt-sm-4 mt-md-5 fs-sm-5">
                <Col>
                  <span className="fs-5">{v.dealership.name}</span>
                  <br />
                  {`${v.dealership.address}, ${v.dealership.postalCode} ${v.dealership.city}`}
                </Col>
              </Row>
              <Row className="mt-2 mt-sm-4 mt-md-5 fs-sm-5">
                <Col className="d-flex align-items-center">
                  <span className="fs-5 fw-bold">{`${t('general-deductible')}: ${v.dealership.defaultDeductible}€`}</span>
                  <Button
                    size="sm"
                    className="d-flex ms-3 fw-bold"
                    onClick={() => setShowTermsAndConditionsModal(true)}
                  >
                    {t('general-termsAndConditionsTitle')}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-sm-3">
                <Col className="justify-content-center d-flex">
                  <ul className="list-unstyled m-0">
                    <li className="d-flex align-items-center mt-3 mt-sm-4 mt-md-5">
                      {dl ? (
                        dl.validTypes.includes(v.driversLicenseType) ? (
                          <>
                            <span className="d-flex align-items-center justify-content-center rounded-circle me-3 fs-6 fs-sm-4 fs-md-3 fs-lg-2 bg-success fw-bold text-white bullet">
                              <FaCheck />
                            </span>
                            <span className="fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('testDrive-driversLicense-valid')}</span>
                          </>
                        ) : (
                          <>
                            <span className="d-flex align-items-center justify-content-center rounded-circle me-3 fs-6 fs-sm-4 fs-md-3 fs-lg-2 bg-danger fw-bold text-white bullet">
                              <FaXmark />
                            </span>
                            <span className="fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('testDrive-driversLicense-invalid')}</span>
                          </>
                        )
                      ) : (
                        <span className="d-inline-block fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('testDrive-driversLicense-notChecked')}</span>
                      )}
                    </li>
                    {ccr && dl?.validTypes.includes(v.driversLicenseType) ? (
                      <li className="d-flex align-items-center mt-3 mt-sm-4 mt-md-5">
                        {ci ? (
                          ci.valid ? (
                            <>
                              <span className="d-flex align-items-center justify-content-center rounded-circle me-3 fs-6 fs-sm-4 fs-md-3 fs-lg-2 bg-success fw-bold text-white bullet">
                                <FaCheck />
                              </span>
                              <span className="fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('testDrive-creditInfo-valid')}</span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex align-items-center justify-content-center rounded-circle me-3 fs-6 fs-sm-4 fs-md-3 fs-lg-2 bg-danger fw-bold text-white bullet">
                                <FaXmark />
                              </span>
                              <span className="fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('testDrive-creditInfo-invalid')}</span>
                            </>
                          )
                        ) : (
                          <span className="d-inline-block fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('testDrive-creditInfo-notChecked')}</span>
                        )}
                      </li>
                    ) : (
                      null
                    )}
                  </ul>
                </Col>
              </Row>
              {dl?.validTypes.includes(v.driversLicenseType) && (!ccr || ci?.valid) ? (
                <>
                  <Row className="mt-3 mt-sm-3 mt-md-4">
                    <Col>
                      <span className="fs-5 fs-sm-4 fs-md-3 fs-lg-2 fw-bold">{t('testDrive-reserveTestDrive')}</span>
                    </Col>
                  </Row>
                  <Row className="mt-3 mt-sm-3 mt-md-4">
                    <Col className="pe-1" xs={7}>
                      <InputGroup size="lg">
                        <InputGroup.Text className="border-primary border-end-0 bg-primary text-white flex-column justify-content-around align-items-center form-icon">
                          <FaCalendar />
                        </InputGroup.Text>
                        <Form.Control
                          className="border-primary border-start-0 text-center fs-4 p-1 fw-bold"
                          value={date}
                          type="date"
                          onChange={(e) => { setDate(e.target.value); getTimeSlots(e.target.value); }}
                        />
                      </InputGroup>
                    </Col>
                    <Col className="ps-1">
                      <InputGroup size="lg">
                        <InputGroup.Text className="border-primary border-end-0 bg-primary text-white flex-column justify-content-around align-items-center form-icon">
                          {timeSlotsPending ? (<ActionSpinner pending noSpace />) : (<FaClock />)}
                        </InputGroup.Text>
                        {timeSlotsPending || !timeSlots ? (
                          <Form.Control
                            className="border-primary border-start-0 text-center fs-4 p-1 fw-bold"
                            disabled
                            value={timeSlotsPending ? t('general-wait') : t('testDrive-noTimeSlots')}
                            color={timeSlotsPending ? 'primary' : 'danger'}
                          />
                        ) : (
                          <Form.Select
                            className="border-primary border-start-0 text-center fs-4 p-1 fw-bold time-select"
                            value={time}
                            onChange={(e) => { setTime(e.target.value); }}
                          >
                            <option value="">{t('general-select')}</option>
                            {timeSlots.map((ts) => (<option value={ts.startTime} key={ts.startTime}>{ts.startTime}</option>))}
                          </Form.Select>
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mt-sm-2 mt-md-3">
                    <Form.Group as={Col} className="justify-content-center d-flex mt-3">
                      <Form
                        noValidate
                        validated={validated}
                        ref={formRef}
                        onSubmit={handleSubmit}
                        className="w-100"
                      >
                        <InputGroup size="lg" hasValidation>
                          <InputGroup.Text className="border-primary border-end-0 bg-primary text-white flex-column justify-content-around align-items-center form-icon">
                            <FaPhone />
                          </InputGroup.Text>
                          <Form.Control
                            className="border-primary border-start-0 text-center fs-4 p-1 fw-bold text-uppercase"
                            placeholder="+358401234567"
                            required
                            pattern="^\+\d{7,16}$"
                            value={phoneNumber}
                            onChange={(e) => { setPhoneNumber(e.target.value); }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('testDrive-phoneNumber-invalid')}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form>
                    </Form.Group>
                  </Row>
                </>
              ) : (
                null
              )}
              <Row className="mt-sm-2 mt-md-3">
                <Col className="justify-content-center d-flex mt-3">
                  <Button
                    className="w-100 fw-bold"
                    size="lg"
                    disabled={pending || (!!dl && !dl.validTypes.includes(v.driversLicenseType)) || (ccr && !!ci && !ci.valid) || (((!!dl && !ccr) || (!!dl && ccr && !!ci)) && (!timeSlots || !time))}
                    onClick={() => {
                      if (!dl) { checkDriversLicense(); return; }
                      if (ccr && !ci) { checkCreditInfo(); return; }
                      formRef?.current?.dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                      );
                    }}
                  >
                    <ActionSpinner pending={pending} />
                    {(() => {
                      if (!dl) return t('testDrive-checkDriversLicense');
                      if (ccr && !ci && dl.validTypes.includes(v.driversLicenseType)) return t('testDrive-checkCreditInfo');
                      return t('general-confirm');
                    })()}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-sm-2 mt-md-3">
                <Col className="mt-3">
                  <b>{t('general-dataSource-driversLicence-title')}</b>
                  {': '}
                  {t('general-dataSource-traficom')}
                </Col>
              </Row>
              <Row className="">
                <Col className="mt-3">
                  <span className="fw-bold">{`${t('general-dataSource-vehicleDetails-title')}:`}</span>
                  {v.fromTraficom ? (
                    <>
                      <p>
                        {t('general-dataSource-traficom')}
                        {': '}
                        {t('general-dataSource-vehicleDetails-alwaysTrafiom2')}
                      </p>
                      <p>
                        {t('general-dataSource-koeaja')}
                        {': '}
                        {t('general-dataSource-vehicleDetails-other')}
                      </p>
                    </>
                  ) : (
                    <p>
                      {t('general-dataSource-koeaja')}
                      {': '}
                      {t('general-dataSource-vehicleDetails-all')}
                    </p>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-muted">
              <StarOverButton api={api} />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Koeaja;
